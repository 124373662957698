<template>
    <div class="p-grid">
        <div class="p-col-6 card">
            <h5>{{$t('Taller')}}</h5>
            <div class="p-grid card">
                <div class="p-col-9">
                    {{$t('Nombre del Taller')}}: {{ benef.taller }}
                </div>
                <div class="p-col-3">
                    {{$t('Nº Días')}}: {{ benef.dias }}
                </div>
                <div class="p-col-12">
                    {{$t('Observaciones')}}: {{ benef.observacion }}
                </div>
                <div class="p-col-12">
                    {{$t('Se observó daños ocultos')}}: {{benef.oculto}}
                </div>
            </div>			
        </div>
        <div class="p-col-6 card" style="background:#E7E7D9;">
            <h5>{{$t('Documentos')}}</h5>
            <div class="p-grid card">
                <div class="p-col-12"> 
                    <DataTable :value="anexos" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
						:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                        <Column :header="$t('Descripción')">
                            <template #body="slotProps">
                                {{slotProps.data.descripcion}}
                            </template>
                        </Column>
                        <Column :header="$t('Ver')" headerStyle="width: 20%">
                            <template #body="slotProps">
                                <img style="width: 25px;" :src="url.upload+slotProps.data.codigo" v-if="slotProps.data.format=='jpg'"  @click="IMG(url.upload+slotProps.data.codigo)"/>
                                <img style="width: 25px;" src="images/pdf.jpg" v-if="slotProps.data.format=='pdf'" @click="VerPDF(slotProps.data.codigo)"/>
                            </template>
                        </Column>
                    </DataTable>
                    <OverlayPanel ref="op1" appendTo="body" :showCloseIcon="true">
                        <img style="width: 350px;" :src="imagen"/>
                    </OverlayPanel>										
				</div>
            </div>
        </div>
        <div class="p-col-12 card">
            <div class="p-grid card">
                <div class="p-col-12"> 
                    <DataTable :value="foren" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                        <Column :header="$t('Descripción')">
                            <template #body="slotProps">
                                {{slotProps.data.descripcion}}
                            </template>
                        </Column>
                        <Column :header="$t('Costo')" headerStyle="width: 10%">
                            <template #body="slotProps">
                                {{slotProps.data.costo}}
                            </template>
                        </Column>
                        <Column :header="$t('Cantidad')" headerStyle="width: 10%">
                            <template #body="slotProps">
                                {{slotProps.data.cantidad}}
                            </template>
                        </Column>
                        <Column :header="$t('Total')" headerStyle="width: 10%">
                            <template #body="slotProps">
                                {{slotProps.data.costo*slotProps.data.cantidad}}
                            </template>
                        </Column>
                        <Column :header="$t('De segunda')" headerStyle="width: 10%">
                            <template #body="slotProps">
                                <div v-if="slotProps.data.segunda">
                                    {{ $t('Sí') }}
                                </div>
                                <div>
                                    {{ $t('No') }}
                                </div>
                            </template>
                        </Column>
                        <Column :header="$t('Genérico')" headerStyle="width: 10%">
                            <template #body="slotProps">
                                <div v-if="slotProps.data.generico">
                                    {{ $t('Sí') }}
                                </div>
                                <div>
                                    {{ $t('No') }}
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div class="p-col-8">
                    <div class="p-grid card">
                        <div class="p-col-4">
                            <strong>Subtotal:</strong> 
                        </div>
                        <div class="p-col-8">
                            <strong>{{benef.subtotal}}</strong> 	
                        </div>
                        <div class="p-col-4">
                            <strong>{{$t('Impuesto')}} % :</strong> 	
                        </div>
                        <div class="p-col-4">
                            <strong>{{parseFloat(benef.impuesto)}}</strong> 	
						</div>
                        <div class="p-col-4">
                            <strong>{{(parseFloat(benef.subtotal)*parseFloat(benef.impuesto/100)).toFixed(2)}}</strong> 
                        </div>
                        <div class="p-col-4">
                            <strong>{{$t('Tiempo perdido')}}:</strong> 	
                        </div>
                        <div class="p-col-4">
                            <strong>{{benef.timeloss}}</strong> 	
						</div>
                        <div class="p-col-4">
                            <strong>{{(parseFloat(benef.dias)*parseFloat(benef.timeloss)).toFixed(2)}}</strong> 
                        </div>
                        <div class="p-col-4">
                            <strong>{{$t('Labor de Trabajo')}}:</strong> 
                        </div>
                        <div class="p-col-4">
                            <strong>{{benef.trabajo}}</strong>
						</div>
                        <div class="p-col-2">
                            <strong>{{parseFloat(benef.trabajo).toFixed(2)}}</strong> 
                        </div>
                        <div class="p-col-4">
                            <strong>{{$t('Labor de Pintura')}}:</strong> 
                        </div>
                        <div class="p-col-4">
                            <strong>{{benef.pintura}}</strong>
						</div>
                        <div class="p-col-2">
                            <strong>{{parseFloat(benef.pintura).toFixed(2)}}</strong> 	
                        </div>
                        <div class="p-col-10">
                            <h3><strong>{{$t('Total')}}:</strong></h3>
                        </div>
                        <div class="p-col-2">
                            <h3><strong>{{(parseFloat(benef.subtotal)*parseFloat(1+benef.impuesto/100)-parseFloat(benef.descuento)+parseFloat(benef.trabajo)+parseFloat(benef.pintura)+parseFloat(benef.dias)*parseFloat(benef.timeloss)).toFixed(2)}}</strong></h3>
						</div>
                    </div>                  
                </div>
                <div class="p-col-4">
                    <div class="p-grid card" v-if="edicion">
                        <div class="p-col-12">
                            <Button :label="$t('Documento de Aceptación')" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver()"/>
						</div>
						<div class="p-col-12">
							<Checkbox v-model="benef.taller_activo" :binary="true" :disabled="estado>11"/>
							{{$t('Pago a Taller')}}
						</div>
                    </div>                  
                </div>
            </div>
        </div>

        <div class="p-col-12 card" v-if="edicion && benef.taller_activo">
            <h4>{{$t('Taller')}}</h4>
            <div class="p-grid card">
                <div class="p-col-2">
                    <label>{{$t('Número ID')}}</label>
                    <InputText v-model="benef.taller_code" type="text" :disabled="estado>11" style="width: 100%;"/>
                </div>
                <div class="p-col-4">
                    <label>{{$t('Nombre')}}</label>
                    <InputText v-model="benef.taller" type="text" :disabled="estado>11" style="width: 100%;"/>
                </div>
                <div class="p-col-2">
                    <label>{{$t('Teléfono móvil')}}</label>
                    <InputMask v-model="benef.taller_tlf" mask="+99999 999999" :disabled="estado>11" style="width: 100%;"/>
                    <small class="p-error" v-if="i18n.locale() == 'es'">Incluir código del país</small>
                    <small class="p-error" v-if="i18n.locale() == 'en'">Include country code</small>
                </div>
                <div class="p-col-4">
                    <label>{{$t('Correo')}}</label>
                    <InputText v-model="benef.taller_correo" type="email" :disabled="estado>11" style="width: 100%;"/>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import url from "@/service/_URL";
	import PDF from "../service/PDF";

	export default {
        props: {
            datos: {
                type: Object,
                default: null,
            },
            tipo_veh: {
                type: Array,
                default: null,
            },
            estados: {
                type: Number,
                default: 0,
            },
            edit: {
                type: Boolean,
                default: false,
            },
        },
        data() {
			return {
				i18n: null,
                url: url,
                edicion: this.edit,
                estado: this.estados,
                inf: this.datos,
                benef: this.datos.beneficiario,
                anexos: this.datos.anexos,
                foren: this.datos.foren,
                descripcion: null,
                img: null,
				pdf: null,
                selectedCustomers: null,
                filters: {},
                tipos: this.tipo_veh,
			}
		},
		created() {
			this.i18n = useI18nPlugin();
		},
        methods: {
            IMG(imegan){
				this.imagen = imegan;
				this.toggle1(event);
			},
			toggle1(event) {
				this.$refs.op1.toggle(event);
			},
			VerPDF(document){
				window.open(url.upload+document+'.pdf', '_blank');
			},
			Ver(){
				const doc = new PDF();
				doc.Aceptacion(this.datos,2);
			},

        }
	}
</script>
